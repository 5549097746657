import React from 'react';
import { BaseText } from 'uangcermat-web-toolkit-v2';

function NotFoundPage() {
  return (
    <div className="w-full h-full flex justify-center items-center gap-x-2">
      <BaseText label="404" className="text-4xl text-dark-bermudaGray" />
      <div className="w-[1px] h-12 bg-dark-bermudaGray" />
      <BaseText label="page cannot be found." className="text-2xl text-dark-bermudaGray" />
    </div>
  );
}

export default NotFoundPage;
